import { useTranslation } from 'react-i18next';

export function General() {
  const { i18n } = useTranslation('offer-package');

  if (i18n.language === 'de') {
    return (
      <div className="bg-white border shadow-md rounded-md overflow-clip p-5">
        <h2 className="font-bold">
          Reisen Sie wohl und kommen Sie stressfrei an
        </h2>
        <h3 className="font-bold ms-2 mt-1">Flughäfen</h3>
        <p className="ms-2">
          Der Flughafen Graz liegt rund 40 km und der Flughafen Wien Schwechat
          rund 1 ½ Stunden Autofahrt entfernt.
        </p>
        <h3 className="font-bold ms-2 mt-1">Bahnhof Bad Waltersdorf</h3>
        <p className="ms-2">
          2 Minuten Transferzeit, bis Sie in den steirischen Wellness- &
          Ayurveda-Kosmos eintauchen.
        </p>
        <h3 className="font-bold ms-2 mt-1">Mit dem Auto</h3>
        <p className="ms-2">
          Auf der Südautobahn A2 bis zur Abfahrt Bad Waltersdorf/Sebersdorf;
          weiter Richtung Bad Waltersdorf, durch den Ort Richtung Therme, vor
          der Therme nach links. Unsere kostenfreien Parkplätze finden Sie
          direkt vor und hinter unserem Ayurveda Resort Mandira.
        </p>

        <h2 className="font-bold mt-4">Stornierungsbedingungen</h2>
        <ul className="ms-4 list list-disc">
          <li>29 Tage bis 20 Tage vor Anreise: 25 % des Angebotspreises</li>
          <li>19 Tage bis 10 Tage vor Anreise: 50 % des Angebotspreises</li>
          <li>9 Tage bis 5 Tage vor Anreise 75 % des Angebotspreises</li>
          <li>Ab 4 Tagen vor Anreise: 100 % des Angebotspreises</li>
        </ul>

        <p className="mt-2">
          Als besondere Serviceleistung bieten wir Ihnen den Abschluss der
          Europäischen Reiseversicherung „Hotelstorno Plus“ an. Sie können diese
          selbstverständlich auch selbst abschließen.
        </p>

        <h2 className="font-bold mt-4">Tourismusabgabe</h2>
        <p>
          Alle Preise verstehen sich exkl. € 3,50 Nächtigungs- und Kurabgabe.
        </p>

        <h3 className="font-bold mt-4">An- und Abreise</h3>
        <p>
          Am Anreisetag steht Ihnen Ihr Zimmer ab 15.00 Uhr zur Verfügung. Am
          Zimmer steht bereits eine Badetasche mit Bademantel & Slipper für Sie
          bereit, die Sie während Ihres Aufenthalts kostenfrei nutzen können.
          Sollten Sie bereits vor 15.00 Uhr unseren Wellness- und Saunabereich
          nutzen möchten, ist dies gerne in Form unsere Frühanreisepauschale zum
          Aufpreis von 40 € pro Person möglich! Diese inkludiert die Nutzung des
          Wellness und Saunabereichs ab 9.00 sowie das Mittagessen am
          Anreisetag. Buchbar direkt vor Ort.
        </p>

        <h3 className="font-bold mt-4">Check-out</h3>
        <p>
          Am Abreisetag bitten wir Sie, Ihr Zimmer bis 11.00 Uhr freizugeben.
          Die Nutzung des Ayurveda & Thermal Spa mit unseren Garderoben bis
          11.00 Uhr ist für Sie inkludiert. Eine Verlängerung bis 18.00 Uhr
          inklusive Mittagessen und Nutzung unseres Wellness- und Saunabereich
          sowie unseren Garderoben mit versperrbaren Kästchen kostet 40 € pro
          Person.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white border shadow-md rounded-md overflow-clip p-6">
      <h2 className="font-bold">Travel well and arrive stress-free</h2>
      <h3 className="font-bold ms-2 mt-1">Airports</h3>
      <p className="ms-2">
        Graz Airport is around 40 km away and Vienna Schwechat Airport is around
        1 ½ hours away by car.
      </p>
      <h3 className="font-bold ms-2 mt-1">Bad Waltersdorf train station</h3>
      <p className="ms-2">
        2 minutes transfer time until you can immerse yourself in the Styrian
        wellness & Ayurveda cosmos.
      </p>
      <h3 className="font-bold ms-2 mt-1">With car</h3>
      <p className="ms-2">
        Take the A2 Südautobahn to the Bad Waltersdorf/Sebersdorf exit; continue
        in the direction of Bad Waltersdorf, through the village in the
        direction of the Therme turn left. You will find our free parking spaces
        directly in front of and behind our Ayurveda Resort Mandira.
      </p>

      <h2 className="font-bold mt-4">Cancellation conditions</h2>
      <ul className="ms-4 list list-disc">
        <li>29 days to 20 days before arrival: 25 % of the offer price</li>
        <li>19 days to 10 days before arrival: 50 % of the offer price</li>
        <li>9 days to 5 days before arrival 75 % of the offer price</li>
        <li>From 4 days before arrival: 100 % of the offer price</li>
      </ul>

      <p className="mt-2">
        As a special service we offer you the conclusion of the European Travel
        Insurance “Hotel Cancellation Plus”. You can of course of course also
        take out this insurance yourself.
      </p>

      <h2 className="font-bold mt-4">Tourism tax</h2>
      <p>All prices exclude € 3.50 overnight stay and spa tax.</p>

      <h3 className="font-bold mt-4">Arrival and departure</h3>
      <p>
        On the day of arrival your room will be available from 15.00 hrs. In the
        room you will find a bathing bag with bathrobe and slippers which you
        can use free of charge during your stay. If you would like to use our
        wellness and sauna area before 3.00 p.m. sauna area, this is possible in
        the form of our early arrival package for a surcharge of 40 € per
        person! This includes the use of the wellness and sauna area from 9.00
        am and lunch on the day of arrival. Bookable directly on site.
      </p>

      <h3 className="font-bold mt-4">Check-out</h3>
      <p>
        On the day of departure we ask you to vacate your room by 11.00 am. The
        use of the Ayurveda & Thermal Spa with our checkrooms until 11.00 am is
        included for you. An extension until 18.00 hrs including lunch and use
        of our wellness and sauna area and our cloakrooms with lockable lockers
        costs € 40 per person.
      </p>
    </div>
  );
}

export default General;
