import { DateOnly } from '../utility';

export interface IEnquiryOptionDto {
  name: string;
  valueEnabled: boolean;
}

export interface IEnquiryDto {
  from: DateOnly | null;
  to: DateOnly | null;
  flexible: number | null;
  personCount: number | null;

  airportTransfer: boolean | null;
  newsletter: boolean | null;
  contactMail: string;
  contactPhone: string | null;
  contactTitle: string | null;
  contactFirstname: string | null;
  contactLastname: string | null;
  contactLanguage: string | null;

  contactStreet: string | null;
  contactStreetNr: string | null;
  contactPostal: string | null;
  contactCity: string | null;
  contactCountry: string | null;

  promoCode: string | null;
  callback: Date | null;

  roomOptions: IEnquiryOptionDto[];
  treatmentOptions: IEnquiryOptionDto[];
  pricingOptions: IEnquiryOptionDto[];

  message: string | null;
}

export const EmptyEnquiry: IEnquiryDto = {
  from: new DateOnly(new Date()),
  to: new DateOnly(new Date()),

  flexible: null,
  personCount: null,

  airportTransfer: false,
  newsletter: false,
  contactMail: '',
  contactPhone: null,
  contactTitle: null,
  contactFirstname: null,
  contactLastname: null,
  contactLanguage: null,

  contactStreet: null,
  contactStreetNr: null,
  contactPostal: null,
  contactCity: null,
  contactCountry: null,

  promoCode: null,
  callback: null,

  roomOptions: [],
  treatmentOptions: [],
  pricingOptions: [],

  message: null,
};
